<!-- index -->
<template>
  <div class="about" v-if="!$root.isApp">
    <div class="main-theme">
      <div class="main-title">
        <span class="title-us">TALENT RECRUITMENT</span>
        <span class="title-cn">人才招聘</span>
      </div>
    </div>
    <div class="main-content">
      <p class="content-title">FBA业务/跨境电商销售</p>
      <div class="recruit-msg">
        <div class="msg-item">
          <span>
            工作地点：深圳市-南山区
            <br />
            <br />
            工作类型：全职
          </span>
        </div>
        <div class="msg-item">
          <span>
            工作经验：
            <br />
            <br />
            招聘人数：2
          </span>
        </div>
        <div class="msg-item">
          <span>
            学历：大专及以上
            <br />
            <br />
            发布时间：2020-07-09
          </span>
        </div>
      </div>
      <div class="recruit-desc">
        <p class="desc-title">职位描述</p>
        <p class="desc-content">
          1、负责跨境电商物流服务产品的推广(亚马逊FBA头程海运)；
          <br />
          <!-- <br /> -->
          2、通过不同渠道（网站、展会等）收集相关客户信息进行整理、跟进、汇总、分析。
          <br />
          <!-- <br /> -->
          3、维护客户关系以及客户间的长期战略合作计划，定期维护老客户，建立良好长期合作关系，为客户提供更方便、快捷、经济的物流解决方案；
        </p>
      </div>
      <div class="recruit-desc">
        <p class="desc-title">任职要求</p>
        <p class="desc-content">
          1、美线FBA销售一年及以上经验优先考虑；
          <!-- <br /> -->
          <br />
          2、有一定的市场分析及判断能力，良好的客户服务意识
          <!-- <br /> -->
          <br />
          3、有一定的物流行业基础知识，有一定英语基础优先考虑。
        </p>
      </div>
    </div>
    <div class="contactUs" @click="tous">
      <img src="../../assets/img/pc/icon_online_counsel_pc.png" alt="" />
      <span> 在线咨询 </span>
    </div>
  </div>
  <div v-else class="m_recruit">
    <div class="recruit_top">
      <div class="m_top_div">
        <div class="m_logo" @click="goUrl()"></div>
        <div class="m_wechats" @click="toown">
          <img src="../../assets/img/m/icon_online_counsel_pc.png" alt="" />
          <span>在线咨询</span>
        </div>
      </div>

      <div class="recruit_title">
        <span>人才招聘</span>
      </div>
    </div>
    <div class="recruit_main">
      <div class="main_re">
        <div class="top_main">
          <span class="top_left">
            <span style="font-family: sans-serif; display: inline-block"
              >FBA业务跨境电商销售</span
            >
            <!-- <span style="margin-right:2px;display:inline-block"></span> -->
          </span>
          <span class="top_right">2020.07.09</span>
        </div>
        <ul class="main_request">
          <li v-for="(item, index) in requestList" :key="index">
            <img :src="item.img" alt="" />
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <div class="main_desc">
          <h1>职位描述</h1>
          <p>1、负责跨境电商物流服务产品的推广(亚马逊 FBA头程海运)；</p>
          <p>
            2、通过不同渠道（网站、展会等）收集相关客户信息进行整理、跟进、汇总、分析。
          </p>
          <p>
            3、维护客户关系以及客户间的长期战略合作计划，定期维护老客户，建立良好长期合作关系，为客户提供更方便、快捷、经济的物流解决方案；
          </p>
        </div>
        <div class="main_desc">
          <h1>任职要求</h1>
          <p>1、美线FBA销售一年及以上经验优先考虑；</p>
          <p>2、有一定的市场分析及判断能力，良好的客户 服务意识；</p>
          <p>3、有一定的物流行业基础知识，有一定英语基 础优先考虑。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入的其他文件 例如：import moduleName from 'modulePath';
export default {
  name: "About",
  //import所引入的组件注册
  components: {},

  data() {
    return {
      requestList: [
        {
          img: require("../../assets/img/mrecruit/icon_people_member.png"),
          label: "招2人",
        },
        {
          img: require("../../assets/img/mrecruit/icon_education_background.png"),
          label: "大专及以上",
        },
        {
          img: require("../../assets/img/mrecruit/icon_time.png"),
          label: "经验不限",
        },
        {
          img: require("../../assets/img/mrecruit/icon_full_time.png"),
          label: "全职",
        },
        {
          img: require("../../assets/img/mrecruit/icon_address.png"),
          label: "深圳市南山区",
        },
      ],
    };
  },

  //监听属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
    toown() {
      window.open('https://webpage.qidian.qq.com/2/chat/h5/index.html?linkType=1&env=ol&kfuin=2885822797&fid=956&key=96a6cdc3dab9e8c0318256ded88782c2&cate=7&source=0&isLBS=0&isCustomEntry=0&type=10&ftype=1&_type=wpa&qidian=true&translateSwitch=0&roleValue=0&roleData=3007864257')
    },
    //联系我们
    tous() {
      window.open("https://wpa1.qq.com/byOBtjMx?_type=wpa&qidian=true");
    },
    goUrl() {
      this.$router.push({ path: "/" });
    },
  },

  //创建完成 访问当前this实例
  created() {},
  //挂载完成 访问DOM元素
  mounted() {},
};
</script>
<style scoped lang="scss">
.about {
  width: 100%;
  min-height: calc(100vh - 92px);
  background: #eef2f6;
}
.desc-content {
  line-height: 1.5;
}
.m_recruit {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  background: #EEF2F6;
  overflow: auto;
  .recruit_top {
    width: 100%;
    box-sizing: border-box;
    height: 196px;
    background: #2370BB;
    padding: 16px 18px 0 18px;
    z-index: -9;
    .m_top_div {
      width: 100%;
      height: 23px;
      display: flex;
      justify-content: space-between;
      .m_logo {
        width: 123px;
        height: 23px;
        background-image: url("../../assets/img/mindex/logo_fbahope.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .m_wechats {
        height: 23px;
        width: 90px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.5);
        // opacity: 0.5;
        border-radius: 28px;
        padding: 0 7px;
        color: #fff;
        font-size: 13px;
        line-height: 23px;
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width: 16px;
          height: 16px;
          display: inline-block;
        }
        span {
          display: inline-block;
        }
      }
    }

    .recruit_title {
      width: 100px;
      margin: 32px auto;
      text-align: center;
      height: 24px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      position: relative;
      span {
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
    }
    .recruit_title span::before,
    .recruit_title span::after {
      background: #fff;
      content: "";
      height: 5px;
      position: absolute;
      display: flex;
      top: 42%;
      width: 5px;
      border-radius: 50%;
    }
    .recruit_title span::before {
      left: -6px;
    }
    .recruit_title span::after {
      right: -7px;
    }
    .recruit_title::before,
    .recruit_title::after {
      background: #fff;
      content: "";
      height: 1px;
      position: absolute;
      display: flex;
      top: 49%;
      width: 30%;
    }
    .recruit_title::before {
      left: -36px;
    }
    .recruit_title::after {
      right: -36px;
    }
  }
  .recruit_main {
    position: absolute;
    top: 114px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 18px 20px 18px;
    z-index: 1;
    .main_re {
      width: 100%;
      box-sizing: border-box;
      background: #fff;
      border-radius: 6px;
      padding: 20px 15px 1px 15px;
      .top_main {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .top_left {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: #343537;
        }
        .top_right {
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: #989EA4;
        }
      }
      .main_request {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 13px 0 13px;
        background: #F5F7FA;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        // justify-content: space-around;
        margin-bottom: 30px;
        li {
          display: flex;
          font-size: 13px;
          font-weight: 500;
          color: #696D71;
          min-width: 27%;
          line-height: 18px;
          margin-right: 3%;
          margin-bottom: 16px;
          img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }
        li:last-child {
          width: 66%;
          margin-right: 0 !important;
        }
        li:nth-child(3) {
          margin-right: 0 !important;
        }
        li:nth-child(2) {
          margin-right: 10% !important;
        }
        // li:nth-child(1) {
        //   width: 28%;
        // }
        // li:nth-child(4) {
        //   width: 28%;
        // }
        // li:nth-child(3) {
        //   width: 30%;
        // }
      }
      .main_desc {
        width: 100%;
        box-sizing: border-box;
        margin: 10px 0 30px;
        h1 {
          font-size: 17px;
          font-weight: bold;
          color: #343537;
          margin-bottom: 16px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #696D71;
          display: flex;
          flex-wrap: wrap;
          line-height: 26px;
        }
      }
    }
  }
}
.contactUs {
  position: fixed;
  right: 0;
  bottom: 220px;
  width: 40px;
  height: 132px;
  background: #135697;
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  img {
    display: block;
    margin: 0 auto;
    padding: 0 0 5px 0;
    box-sizing: border-box;
  }
  span {
    display: block;
    width: 16px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/base.scss";
</style>